import { render, staticRenderFns } from "./DeleteUserButton.vue?vue&type=template&id=1c598c94&scoped=true&"
import script from "./DeleteUserButton.vue?vue&type=script&lang=js&"
export * from "./DeleteUserButton.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c598c94",
  null
  
)

export default component.exports